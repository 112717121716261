import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./i18n/i18n";

import { RootState } from "./types/rtk/api-config/types";
import MainPage from "./components/pages/MainPage/MainPage";
import ErrorPage from "./components/pages/ErrorPage/ErrorPage";
import { AppRoutes } from "./routes/AppRoutes";
import InfoValidation from "./components/pages/InfoValidation/InfoValidation";
import { useMemo } from "react";

const App = () => {
  const theme = useSelector((state: RootState) => state.theme.mode);
  useMemo(() => {
    document.body.className = `theme--${theme}`;
  }, [theme]);

  const router = createBrowserRouter([
    {
      path: AppRoutes.BASE_ROUTE,
      element: <MainPage />,
      errorElement: <ErrorPage />,
    },
    {
      path: AppRoutes.INFOS_VALIDATIONS,
      element: <InfoValidation />,
      errorElement: <ErrorPage />,
    },
  ]);

  return (
    <Box className={`App`}>
      <RouterProvider router={router} />
    </Box>
  );
};

export default App;
