import { FormEvent } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import InputText from "../../atoms/InputText/InputText";
import StyledButton from "../../atoms/StyledButton/StyledButton";
import { QuestionProps } from "../../../types/components/molecules/questionProps";

const Seconde = ({
  formData,
  setFormData,
  timeLine,
  setTimeLine,
}: QuestionProps) => {
  const { t } = useTranslation();

  //Close keyboard on Enter press
  const onSubmit = (e: FormEvent) => {
    //@ts-ignore
    e.target[0].blur();
    e.preventDefault();
  };

  return (
    <Box className="questionsContainer">
      <Box component={"form"} onSubmit={onSubmit}>
        <InputText
          label={t("animalInfo:QUESTION_TWO.NAME")}
          value={formData.name ?? ""}
          onChange={(value) => setFormData({ ...formData, name: value })}
        />
      </Box>
      <StyledButton
        style={{ marginBottom: "2rem", marginTop: "auto" }}
        onClick={() => {
          setTimeLine(timeLine + 1);
        }}
        disabled={formData.name === ""}
      >
        {t("animalInfo:NEXT_BUTTON")}
      </StyledButton>
    </Box>
  );
};

export default Seconde;
