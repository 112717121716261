import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";

import Title from "../../atoms/Title/Title";
import InputSelect from "../../atoms/InputSelect/InputSelect";
import { Animal } from "../../../types/rtk/animal/animal";
import Container from "../../templates/Container/Container";
import Header from "../../templates/Header/Header";
import InputText from "../../atoms/InputText/InputText";
import InputDate from "../../atoms/InputDate/InputDate";
import InputNumber from "../../atoms/InputNumber/InputNumber";
import InputAutocomplete from "../../atoms/InputAutocomplete/InputAutocomplete";
import { getCatBreedList, getDogBreedList } from "../../../assets/animal-breed";
import { AppRoutes } from "../../../routes/AppRoutes";

const InfoValidation = () => {
  const { t } = useTranslation();
  let location = useLocation();
  const navigate = useNavigate();
  const [id, setId] = useState("");
  const [formData, setFormData] = useState<Partial<Animal>>({
    activity_level: null,
    birth_date: "",
    breed: "",
    identification: "",
    is_sterilized: null,
    last_vaccination_date: "",
    last_vet_visit_date: "",
    name: "",
    sex: "",
    species: "",
    tracker_id: 0,
    weight: "0",
  });
  const options =
    formData?.species === "cat" ? getCatBreedList(t) : getDogBreedList(t);

  useEffect(() => {
    if (location && location.state && location.state.formData) {
      setFormData(location.state.formData);
      setId(location.state.id);
    } else {
      navigate(AppRoutes.BASE_ROUTE);
    }
  }, [location, navigate]);

  return (
    <Container>
      <Header formData={formData} id={id}>
        <Box className="InfoValidation">
          <Title variant="h3">
            {t("animalInfo:PROFIL_VALIDATION.INDENTIFICATION_INFOS")}
          </Title>
          <Box className="dataDisplay">
            <InputSelect
              label={t("animalInfo:PROFIL_VALIDATION.SPECIES")}
              datas={[
                { label: t("animalInfo:QUESTION_ONE.CAT"), value: "cat" },
                { label: t("animalInfo:QUESTION_ONE.DOG"), value: "dog" },
              ]}
              value={formData.species ?? ""}
              onChange={(event) =>
                setFormData({
                  ...formData,
                  species: event.target.value.toString(),
                })
              }
            />
            <InputText
              label={t("animalInfo:QUESTION_TWO.NAME")}
              value={formData.name ?? ""}
              onChange={(value) => setFormData({ ...formData, name: value })}
            />
          </Box>
          <Title variant="h3">
            {t("animalInfo:PROFIL_VALIDATION.PERSONAL_INFOS")}
          </Title>
          <Box className="dataDisplay">
            <InputDate
              label={t("animalInfo:QUESTION_THREE.BIRTHDATE")}
              value={dayjs(formData.birth_date)}
              onChange={(value) =>
                setFormData({
                  ...formData,
                  birth_date: value?.toISOString(),
                })
              }
            />
            <InputSelect
              label={t("animalInfo:PROFIL_VALIDATION.SEX")}
              datas={[
                { value: "male", label: t("animalInfo:QUESTION_FOUR.FEMALE") },
                { value: "female", label: t("animalInfo:QUESTION_FOUR.MALE") },
              ]}
              value={formData.sex ?? ""}
              onChange={(event) =>
                setFormData({
                  ...formData,
                  sex: event.target.value.toString(),
                })
              }
            />
          </Box>
          <Title variant="h3">
            {t("animalInfo:PROFIL_VALIDATION.PHYSICAL")}
          </Title>
          <Box className="dataDisplay">
            <InputNumber
              label={t("animalInfo:QUESTION_FIVE.WEIGHT")}
              value={formData.weight?.toString()}
              onChange={(value) => setFormData({ ...formData, weight: value })}
              endAdornment="kg"
              maxValue={formData.species === "cat" ? "30" : "150"}
            />
            <InputAutocomplete
              label={t("animalInfo:QUESTION_SIX.RACE")}
              value={options.find((el) => el.value === formData.breed) ?? null}
              options={options}
              onChange={(_event, value) =>
                setFormData({ ...formData, breed: value?.value })
              }
            />
          </Box>
          <Title variant="h3">
            {t("animalInfo:PROFIL_VALIDATION.UNIQUE_IDENTIFICATION")}
          </Title>
          <Box className="dataDisplay">
            <InputSelect
              label={t("animalInfo:QUESTION_SEVEN.IDENTIFICATION")}
              datas={[
                { value: "chip", label: t("animalInfo:QUESTION_SEVEN.CHIPED") },
                {
                  value: "tattoo",
                  label: t("animalInfo:QUESTION_SEVEN.TATTOED"),
                },
                {
                  value: "none",
                  label: t("animalInfo:QUESTION_SEVEN.NOT_IDENTIFIED"),
                },
              ]}
              value={formData.identification ?? ""}
              onChange={(event) =>
                setFormData({
                  ...formData,
                  identification: event.target.value.toString(),
                })
              }
            />
          </Box>
          <Title variant="h3">
            {t("animalInfo:PROFIL_VALIDATION.MEDICAL_HISTORY")}
          </Title>
          <Box className="dataDisplay">
            <InputSelect
              label={t("animalInfo:QUESTION_EIGHT.STERILIZATION_STATUS")}
              datas={[
                {
                  value: "Oui",
                  label: t("animalInfo:QUESTION_EIGHT.STERILIZED"),
                },
                {
                  value: "Non",
                  label: t("animalInfo:QUESTION_EIGHT.NOT_STERILIZED"),
                },
              ]}
              value={formData.is_sterilized ? "Oui" : "Non"}
              onChange={(event) =>
                setFormData({
                  ...formData,
                  is_sterilized:
                    event.target.value.toString().toLocaleLowerCase() === "oui"
                      ? true
                      : false,
                })
              }
            />
            <InputDate
              label={t("animalInfo:QUESTION_NINE.LAST_VISIT")}
              value={dayjs(formData.last_vet_visit_date)}
              onChange={(value) =>
                setFormData({
                  ...formData,
                  last_vet_visit_date: value?.toISOString(),
                })
              }
              minDate={dayjs(formData.birth_date)}
            />
            <InputDate
              label={t("animalInfo:QUESTION_TEN.LAST_VACCIN")}
              value={dayjs(formData.last_vaccination_date)}
              onChange={(value) =>
                setFormData({
                  ...formData,
                  last_vaccination_date: value?.toISOString(),
                })
              }
              minDate={dayjs(formData.birth_date)}
            />
          </Box>
          <Title variant="h3">
            {t("animalInfo:PROFIL_VALIDATION.ACTIVITY")}
          </Title>
          <Box className="dataDisplay">
            <InputSelect
              label={t("animalInfo:QUESTION_ELEVEN.EXIT_FREQUENCY")}
              datas={[
                { value: 0, label: t("animalInfo:QUESTION_ELEVEN.RARELY") },
                { value: 33, label: t("animalInfo:QUESTION_ELEVEN.MOSTLY") },
                { value: 66, label: t("animalInfo:QUESTION_ELEVEN.OFTEN") },
                {
                  value: 100,
                  label: t("animalInfo:QUESTION_ELEVEN.EVERYTIME"),
                },
              ]}
              value={formData.activity_level?.toString() ?? ""}
              onChange={(event) =>
                setFormData({
                  ...formData,
                  activity_level: parseInt(event.target.value.toString()),
                })
              }
            />
          </Box>
        </Box>
      </Header>
    </Container>
  );
};

export default InfoValidation;
