import { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";

interface Props {
  label: string;
  datas: { value: number | string | undefined; label: string }[];
  value: string;
  onChange?: (event: SelectChangeEvent<HTMLInputElement>) => void;
  className?: string;
}

const InputSelect = ({ label, datas, value, onChange, className }: Props) => {
  const [val, setVal] = useState<string>("");

  const handleChange = (event: SelectChangeEvent<HTMLInputElement>) => {
    setVal(event.target.value as string);
    if (onChange) onChange(event);
  };

  useEffect(() => {
    if (value) {
      setVal(value);
    }
  }, [value]);

  return (
    <FormControl fullWidth className={`InputSelect ${className}`}>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={val as unknown as HTMLInputElement}
        label={label}
        onChange={handleChange}
      >
        {datas.map((data, index) => (
          <MenuItem key={index} value={data.value}>
            {data.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default InputSelect;
