import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
import { DE } from "./locales/de";
import { FR } from "./locales/fr";
import { EN } from "./locales/en";
import { IT } from "./locales/it";
import { ES } from "./locales/es";
import { NL } from "./locales/nl";

const resources = {
  de: DE,
  fr: FR,
  en: EN,
  it: IT,
  es: ES,
  nl: NL,
};

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector) //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: "fr",
    debug: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
