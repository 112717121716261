import { ReactSVG } from "react-svg";

interface Props {
  src: string;
  className?: string;
}

const LogoSvg = ({ src, className }: Props) => (
  <ReactSVG className={`LogoSvg ${className}`} src={src} />
);

export default LogoSvg;
