import { Button } from "@mui/material";
import LogoSvg from "../LogoSvg/LogoSvg";

interface Props {
  text: string;
  active: boolean;
  logoPath?: string;
  onClick: () => void;
}

const TouchButton = ({ logoPath, text, active, onClick }: Props) => {
  return (
    <Button
      className={`TouchButton ${active && "activeTouchButton"}`}
      variant="outlined"
      startIcon={logoPath && <LogoSvg src={logoPath} />}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

export default TouchButton;
