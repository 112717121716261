import { Animal } from "../../types/rtk/animal/animal";
import { apiSlice } from "../_api/api-slice";

interface Args {
  id?: string;
  trackerId?: string;
  body?: Partial<Animal>;
}

export const animal = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAnimal: build.query<{ items: Animal[]; total: number }, string>({
      query: (trackerId) => ({
        url: `/animal?tracker_id=${trackerId}`,
        method: "get",
      }),
    }),
    addAnimal: build.mutation<Animal, Args>({
      query: ({ body }) => ({
        url: `/animal`,
        method: "post",
        body,
      }),
    }),
    updateAnimal: build.mutation<Animal, Args>({
      query: ({ body, id }) => ({
        url: `/animal/${id}`,
        method: "put",
        body,
      }),
    }),
  }),

  overrideExisting: false,
});

export const {
  useUpdateAnimalMutation,
  useGetAnimalQuery,
  useAddAnimalMutation,
} = animal;
