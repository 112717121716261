import { useTranslation } from "react-i18next";

import TouchButton from "../../atoms/TouchButton/TouchButton";
import Male from "../../../assets/Male.svg";
import Female from "../../../assets/Female.svg";
import { QuestionProps } from "../../../types/components/molecules/questionProps";

const Fourth = ({
  formData,
  setFormData,
  timeLine,
  setTimeLine,
}: QuestionProps) => {
  const { t } = useTranslation();
  const handleTimeLine = () =>
    setTimeout(() => {
      setTimeLine(timeLine + 1);
    }, 250);

  return (
    <>
      <TouchButton
        text={t("animalInfo:QUESTION_FOUR.FEMALE")}
        logoPath={Female}
        active={formData.sex === "female"}
        onClick={() => {
          setFormData({ ...formData, sex: "female" });
          handleTimeLine();
        }}
      />
      <TouchButton
        text={t("animalInfo:QUESTION_FOUR.MALE")}
        logoPath={Male}
        active={formData.sex === "male"}
        onClick={() => {
          setFormData({ ...formData, sex: "male" });
          handleTimeLine();
        }}
      />
    </>
  );
};

export default Fourth;
