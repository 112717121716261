import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import StyledButton from "../../atoms/StyledButton/StyledButton";
import InputNumber from "../../atoms/InputNumber/InputNumber";
import { QuestionProps } from "../../../types/components/molecules/questionProps";

const Fiveth = ({
  formData,
  setFormData,
  timeLine,
  setTimeLine,
}: QuestionProps) => {
  const { t } = useTranslation();

  return (
    <Box className="questionsContainer">
      <InputNumber
        label={t("animalInfo:QUESTION_FIVE.WEIGHT")}
        value={formData.weight?.toString()}
        onChange={(value) => setFormData({ ...formData, weight: value })}
        endAdornment="kg"
        maxValue={formData.species === "cat" ? "30" : "150"}
      />
      <StyledButton
        style={{ marginBottom: "2rem", marginTop: "auto" }}
        onClick={() => setTimeLine(timeLine + 1)}
        disabled={!formData.weight || formData.weight === "0"}
      >
        {t("animalInfo:NEXT_BUTTON")}
      </StyledButton>
    </Box>
  );
};

export default Fiveth;
