import { ChangeEvent } from "react";
import { InputAdornment, TextField } from "@mui/material";

interface Props {
  label: string;
  value: string;
  onChange: (value: string) => void;
  className?: string;
  endAdornment?: string;
}

const InputText = ({
  label,
  onChange,
  className,
  value,
  endAdornment,
}: Props) => {
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    onChange(newValue);
  };

  return (
    <TextField
      className={`InputText ${className}`}
      label={label}
      value={value}
      onChange={handleInputChange}
      variant="outlined"
      InputProps={
        endAdornment
          ? {
              endAdornment: (
                <InputAdornment position="end">{endAdornment}</InputAdornment>
              ),
            }
          : undefined
      }
    />
  );
};

export default InputText;
