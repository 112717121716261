import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import TouchButton from "../../atoms/TouchButton/TouchButton";
import { Animal } from "../../../types/rtk/animal/animal";
import { AppRoutes } from "../../../routes/AppRoutes";
import { QuestionProps } from "../../../types/components/molecules/questionProps";

const Eleventh = ({ formData, setFormData }: Partial<QuestionProps>) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleTimeLine = (formData: Partial<Animal>) => {
    setTimeout(() => {
      if (setFormData) {
        setFormData(formData);
        navigate(AppRoutes.INFOS_VALIDATIONS, {
          state: { formData, noArrow: false },
        });
      }
    }, 250);
  };

  return (
    <>
      <TouchButton
        text={t("animalInfo:QUESTION_ELEVEN.RARELY")}
        active={formData?.activity_level === 0}
        onClick={() => handleTimeLine({ ...formData, activity_level: 0 })}
      />
      <TouchButton
        text={t("animalInfo:QUESTION_ELEVEN.MOSTLY")}
        active={formData?.activity_level === 33}
        onClick={() => handleTimeLine({ ...formData, activity_level: 33 })}
      />
      <TouchButton
        text={t("animalInfo:QUESTION_ELEVEN.OFTEN")}
        active={formData?.activity_level === 66}
        onClick={() => handleTimeLine({ ...formData, activity_level: 66 })}
      />
      <TouchButton
        text={t("animalInfo:QUESTION_ELEVEN.EVERYTIME")}
        active={formData?.activity_level === 100}
        onClick={() => handleTimeLine({ ...formData, activity_level: 100 })}
      />
    </>
  );
};

export default Eleventh;
