import { FormEvent } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import StyledButton from "../../atoms/StyledButton/StyledButton";
import InputAutocomplete from "../../atoms/InputAutocomplete/InputAutocomplete";
import { getCatBreedList, getDogBreedList } from "../../../assets/animal-breed";
import { QuestionProps } from "../../../types/components/molecules/questionProps";

const Sixth = ({
  formData,
  setFormData,
  timeLine,
  setTimeLine,
}: QuestionProps) => {
  const { t } = useTranslation();
  const options =
    formData.species === "cat" ? getCatBreedList(t) : getDogBreedList(t);

  //Close keyboard on Enter press
  const onSubmit = (e: FormEvent) => {
    //@ts-ignore
    e.target[0].blur();
    e.preventDefault();
  };

  return (
    <Box className="questionsContainer">
      <Box component={"form"} onSubmit={onSubmit}>
        <InputAutocomplete
          label={t("animalInfo:QUESTION_SIX.RACE")}
          value={options.find((el) => el.value === formData.breed) ?? null}
          options={options}
          onChange={(_event, value) =>
            setFormData({ ...formData, breed: value?.value })
          }
        />
      </Box>
      <StyledButton
        style={{ marginBottom: "2rem", marginTop: "auto" }}
        onClick={() => setTimeLine(timeLine + 1)}
        disabled={!formData.breed || formData.breed === ""}
      >
        {t("animalInfo:NEXT_BUTTON")}
      </StyledButton>
    </Box>
  );
};

export default Sixth;
