import { useTranslation } from "react-i18next";

import TouchButton from "../../atoms/TouchButton/TouchButton";
import { QuestionProps } from "../../../types/components/molecules/questionProps";

const Seventh = ({
  formData,
  setFormData,
  timeLine,
  setTimeLine,
}: QuestionProps) => {
  const { t } = useTranslation();
  const handleTimeLine = () =>
    setTimeout(() => {
      setTimeLine(timeLine + 1);
    }, 250);

  return (
    <>
      <TouchButton
        text={t("animalInfo:QUESTION_SEVEN.CHIPED")}
        active={formData.identification === "chip"}
        onClick={() => {
          setFormData({ ...formData, identification: "chip" });
          handleTimeLine();
        }}
      />
      <TouchButton
        text={t("animalInfo:QUESTION_SEVEN.TATTOED")}
        active={formData.identification === "tattoo"}
        onClick={() => {
          setFormData({ ...formData, identification: "tattoo" });
          handleTimeLine();
        }}
      />
      <TouchButton
        text={t("animalInfo:QUESTION_SEVEN.NOT_IDENTIFIED")}
        active={formData.identification === "none"}
        onClick={() => {
          setFormData({ ...formData, identification: "none" });
          handleTimeLine();
        }}
      />
    </>
  );
};

export default Seventh;
