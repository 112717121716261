import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";

import StyledButton from "../../atoms/StyledButton/StyledButton";
import InputDate from "../../atoms/InputDate/InputDate";
import { QuestionProps } from "../../../types/components/molecules/questionProps";

const Nineth = ({
  formData,
  setFormData,
  timeLine,
  setTimeLine,
}: QuestionProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<Dayjs | null>(null);

  useEffect(() => {
    if (formData && formData.last_vet_visit_date !== "") {
      setValue(dayjs(formData.last_vet_visit_date));
    } else if (formData && formData.last_vet_visit_date === "") {
      setValue(null);
    }
  }, [formData]);

  return (
    <Box className="questionsContainer">
      <InputDate
        label={t("animalInfo:QUESTION_NINE.LAST_VISIT")}
        value={value}
        onChange={(value) => setValue(value)}
        minDate={dayjs(formData.birth_date)}
      />
      <StyledButton
        style={{ marginBottom: "2rem", marginTop: "auto" }}
        onClick={() => {
          setFormData({
            ...formData,
            last_vet_visit_date: value?.toISOString(),
          });
          setTimeLine(timeLine + 1);
        }}
        disabled={value === null}
      >
        {t("animalInfo:NEXT_BUTTON")}
      </StyledButton>
    </Box>
  );
};

export default Nineth;
