import { ChangeEvent, useEffect, useState } from "react";
import { InputAdornment, TextField } from "@mui/material";

interface Props {
  label: string;
  onChange: (value: string) => void;
  inputLength?: number;
  value?: string;
  className?: string;
  endAdornment?: string;
  maxValue?: string;
}

const InputNumber = ({
  label,
  value,
  onChange,
  className,
  endAdornment,
  maxValue,
}: Props) => {
  const [inputValue, setInputValue] = useState<string>("");

  const isValidInput = (value: string) => {
    const regex = /^(?!.*\..*\.)[0-9]*(\.[0-9]{0,1})?$/;
    return regex.test(value);
  };

  console.log(maxValue);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    let checkedValue = inputValue;

    if (isValidInput(inputValue)) {
      if (maxValue) {
        checkedValue =
          parseFloat(inputValue) > parseInt(maxValue) ? maxValue : inputValue;
      }
      setInputValue(checkedValue);
      onChange(checkedValue);
    }
  };

  useEffect(() => {
    if (value) setInputValue(value);
  }, [value]);

  return (
    <TextField
      className={`InputNumber ${className}`}
      type="number"
      label={label}
      value={inputValue}
      onChange={handleChange}
      variant="outlined"
      inputProps={{
        inputMode: "numeric",
      }}
      InputProps={
        endAdornment
          ? {
              endAdornment: (
                <InputAdornment position="end">{endAdornment}</InputAdornment>
              ),
            }
          : undefined
      }
    />
  );
};

export default InputNumber;
