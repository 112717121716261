// import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

const ErrorPage = () => {
  // const { t } = useTranslation();

  return <Box className="ErrorPage">Error</Box>;
};

export default ErrorPage;
