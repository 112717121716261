import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateView } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";

interface Props {
  label: string;
  value: Dayjs | null;
  onChange: (value: Dayjs | null) => void;
  className?: string;
  views?: DateView[];
  minDate?: Dayjs;
  maxDate?: Dayjs;
}

const InputDate = ({
  label,
  onChange,
  className,
  value,
  views = ["month", "year"],
  minDate,
  maxDate,
}: Props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        className={`InputDate ${className}`}
        label={label}
        value={value}
        onChange={onChange}
        openTo="month"
        views={views}
        disableFuture
        format="MM / YYYY"
        minDate={minDate}
        maxDate={maxDate}
      />
    </LocalizationProvider>
  );
};

export default InputDate;
