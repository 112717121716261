import { useTranslation } from "react-i18next";

import TouchButton from "../../atoms/TouchButton/TouchButton";
import { QuestionProps } from "../../../types/components/molecules/questionProps";

const Heightth = ({
  formData,
  setFormData,
  timeLine,
  setTimeLine,
}: QuestionProps) => {
  const { t } = useTranslation();
  const handleTimeLine = () =>
    setTimeout(() => {
      setTimeLine(timeLine + 1);
    }, 250);

  return (
    <>
      <TouchButton
        text={t("animalInfo:QUESTION_EIGHT.YES")}
        active={formData.is_sterilized === true}
        onClick={() => {
          setFormData({ ...formData, is_sterilized: true });
          handleTimeLine();
        }}
      />
      <TouchButton
        text={t("animalInfo:QUESTION_EIGHT.NO")}
        active={formData.is_sterilized === false}
        onClick={() => {
          setFormData({ ...formData, is_sterilized: false });
          handleTimeLine();
        }}
      />
    </>
  );
};

export default Heightth;
