import { Dispatch, SetStateAction } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import LogoSvg from "../../atoms/LogoSvg/LogoSvg";
import Title from "../../atoms/Title/Title";
import Text from "../../atoms/Text/Text";
import StyledButton from "../../atoms/StyledButton/StyledButton";
import Begin from "../../../assets/Begin.svg";
import Warning from "../../atoms/Warning/Warning";

interface Props {
  timeLine: number;
  setTimeLine: Dispatch<SetStateAction<number>>;
}

const StartingPage = ({ timeLine, setTimeLine }: Props) => {
  const { t } = useTranslation();
  return (
    <Box className="StartingPage">
      <Box className="block">
        <LogoSvg src={Begin} />
      </Box>
      <Box className="block">
        <Title variant="h3">{t("animalInfo:LANDING_PAGE.TITLE")}</Title>
      </Box>
      <Box className="block text">
        <Text>{t("animalInfo:LANDING_PAGE.TEXT")}</Text>
      </Box>
      <Box className="block text">
        <Warning>{t("animalInfo:LANDING_PAGE.WARNING")}</Warning>
      </Box>
      <Box className="block button">
        <StyledButton onClick={() => setTimeLine(timeLine + 1)}>
          {t("animalInfo:LANDING_PAGE.BUTTON")}
        </StyledButton>
      </Box>
    </Box>
  );
};

export default StartingPage;
