import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Alert, AlertTitle, Box, LinearProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useNavigate } from "react-router-dom";

import { getPercentage } from "../../../helpers/getPercentage";
import Title from "../../atoms/Title/Title";
import StyledButton from "../../atoms/StyledButton/StyledButton";
import { Animal } from "../../../types/rtk/animal/animal";
import {
  useAddAnimalMutation,
  useUpdateAnimalMutation,
} from "../../../rtk/animal/animal";
import { RootState } from "../../../types/rtk/api-config/types";
import { useAppSelector } from "../../../rtk/_config/hooks";
import { AppRoutes } from "../../../routes/AppRoutes";
import SpinnerLoader from "../../atoms/SpinnerLoader/SpinnerLoader";

interface Props {
  children: JSX.Element;
  timeLine?: number;
  setTimeLine?: Dispatch<SetStateAction<number>>;
  formData?: Partial<Animal>;
  id?: string;
}

const Header = ({
  children,
  timeLine = 12,
  setTimeLine,
  formData,
  id,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const trackerId = useAppSelector((state: RootState) => state.auth.trackerId);
  const [updateAnimal] = useUpdateAnimalMutation();
  const [addAnimal] = useAddAnimalMutation();
  const [body, setBody] = useState<Partial<Animal>>();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const handleTimeLine = () => {
    setTimeLine && setTimeLine(timeLine - 1);
  };

  const handleSwitchQuestion = (): string => {
    switch (timeLine) {
      case 1:
        return t("animalInfo:QUESTION_ONE.QUESTION");
      case 2:
        return t("animalInfo:QUESTION_TWO.QUESTION");
      case 3:
        return t("animalInfo:QUESTION_THREE.QUESTION");
      case 4:
        return t("animalInfo:QUESTION_FOUR.QUESTION");
      case 5:
        return t("animalInfo:QUESTION_FIVE.QUESTION");
      case 6:
        return t("animalInfo:QUESTION_SIX.QUESTION");
      case 7:
        return t("animalInfo:QUESTION_SEVEN.QUESTION");
      case 8:
        return t("animalInfo:QUESTION_EIGHT.QUESTION");
      case 9:
        return t("animalInfo:QUESTION_NINE.QUESTION");
      case 10:
        return t("animalInfo:QUESTION_TEN.QUESTION");
      case 11:
        return t("animalInfo:QUESTION_ELEVEN.QUESTION");
      default:
        return "";
    }
  };

  const handlerInput = useCallback(async () => {
    setIsLoading(true);
    try {
      let response;
      formData && id
        ? (response = await updateAnimal({
            id: id ?? "",
            body,
          }).unwrap())
        : (response = await addAnimal({
            body: { ...body, tracker_id: parseInt(trackerId ?? "3") },
          }).unwrap());
      setIsSuccess(true);
      setIsLoading(false);
      setIsAlertOpen(true);
      setTimeout(() => {
        navigate(AppRoutes.INFOS_VALIDATIONS + "?&return=0", {
          state: { formData: body },
        });
      }, 500);
      setTimeout(() => {
        setIsAlertOpen(false);
      }, 1000);
      console.log("Response :", response);
    } catch (error) {
      setIsLoading(false);
      setIsAlertOpen(true);
      console.log("Error :", error);
    }
  }, [updateAnimal, addAnimal, navigate, formData, id, body, trackerId]);

  useEffect(() => {
    if (formData) {
      setBody({
        activity_level: formData.activity_level,
        birth_date: formData.birth_date,
        breed: formData.breed,
        identification: formData.identification,
        is_sterilized: formData.is_sterilized,
        last_vaccination_date: formData.last_vaccination_date,
        last_vet_visit_date: formData.last_vet_visit_date,
        name: formData.name,
        sex: formData.sex,
        species: formData.species,
        tracker_id: formData.tracker_id,
        weight: formData.weight,
      });
    }
  }, [formData]);

  return (
    <Box className="HeaderContainer">
      <Box className="HeaderTemplate">
        <Grid2 container spacing={2} className="header-grid">
          <Grid2 xs={3}>
            {timeLine <= 11 && <ArrowBackIosNewIcon onClick={handleTimeLine} />}
          </Grid2>
          <Grid2 xs={6}>
            <Title variant="h3" className="title">
              {timeLine > 11
                ? t("animalInfo:PROFIL_VALIDATION.TITLE")
                : `${t("animalInfo:QUESTION")} ${timeLine}/11`}
            </Title>
          </Grid2>
          <Grid2 xs />
        </Grid2>
        {timeLine < 12 && (
          <LinearProgress
            variant="determinate"
            value={getPercentage({ value: timeLine, total: 11 })}
            className="linearProgress"
          />
        )}
      </Box>

      <Box className="childrenContainer">
        {timeLine < 12 && (
          <Title variant="h3" className={"question"}>
            {handleSwitchQuestion()}
          </Title>
        )}
        {!isLoading ? children : <SpinnerLoader />}
      </Box>

      {timeLine > 11 && (
        <Box className="stickyFooter">
          {isAlertOpen && (
            <Alert severity={isSuccess ? "success" : "error"}>
              <AlertTitle>{isSuccess ? "Success" : "Error"}</AlertTitle>
            </Alert>
          )}
          <StyledButton
            style={{ marginBottom: "2rem", marginTop: "auto" }}
            onClick={handlerInput}
          >
            {t("animalInfo:PROFIL_VALIDATION.BUTTON")}
          </StyledButton>
        </Box>
      )}
    </Box>
  );
};

export default Header;
