import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Container from "../../templates/Container/Container";
import ComponentSwitcher from "../../organisms/ComponentSwitcher/ComponentSwitcher";
import SpinnerLoader from "../../atoms/SpinnerLoader/SpinnerLoader";
import { useAppDispatch, useAppSelector } from "../../../rtk/_config/hooks";
import { setTheme } from "../../../rtk/theme/theme";
import { RootState } from "../../../types/rtk/api-config/types";
import { useGetTokenMutation } from "../../../rtk/auth/login";
import { setAuth } from "../../../rtk/auth/auth";
import { setUser, useGetMyUserQuery } from "../../../rtk/user/user";
import { useGetAnimalQuery } from "../../../rtk/animal/animal";
import { AppRoutes } from "../../../routes/AppRoutes";

const MainPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  let location = useLocation();
  const { i18n } = useTranslation();
  const dark = searchParams.get("dark");
  const lang = searchParams.get("lang");
  const mailTokenParams = searchParams.get("mail_token");
  const user = useAppSelector((state: RootState) => state.user);
  const mailTokenLocalStore = useAppSelector(
    (state: RootState) => state.auth.mail_token
  );
  const mailToken = mailTokenParams ?? mailTokenLocalStore;
  const trackerIdParams = searchParams.get("trackerId");
  const trackerIdLocalStore = useAppSelector(
    (state: RootState) => state.auth.trackerId
  );
  const trackerId = trackerIdParams ?? trackerIdLocalStore;
  const token = useAppSelector((state: RootState) => state.auth.token);
  const [getToken] = useGetTokenMutation();
  const [timeLine, setTimeLine] = useState(
    location.state && location.state.timeline !== 0
      ? location.state.timeLine
      : 0
  );
  const { data: formData } = useGetAnimalQuery(trackerId ?? "", {
    skip: !token || !trackerId,
  });
  const { data: myUser, isLoading: isLoadingMyUser } = useGetMyUserQuery("", {
    skip: !token,
  });
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (mailToken && trackerId) {
      const fetchToken = async () => {
        try {
          const response = await getToken({ mail_token: mailToken }).unwrap();
          dispatch(
            setAuth({
              token: response.access_token,
              mail_token: mailToken,
              trackerId: trackerId,
            })
          );
          console.log("Response :", response);
        } catch (error) {
          console.log("Error :", error);
        }
      };

      if (token === undefined) {
        fetchToken();
      } else {
        dispatch(
          setAuth({
            token: token,
            mail_token: mailToken,
            trackerId: trackerId,
          })
        );
      }
    }
  }, [getToken, mailToken, trackerId, dispatch, token]);

  useEffect(() => {
    if (lang && lang !== i18n.language) {
      dispatch(
        setUser({
          ...user,
          lang: lang,
        })
      );
    } else if (myUser && myUser.language !== i18n.language) {
      dispatch(
        setUser({
          ...user,
          lang: myUser.language,
        })
      );
    }
  }, [i18n, dispatch, user, lang, myUser]);

  useEffect(() => {
    if (dark) {
      dispatch(setTheme({ mode: dark === "0" ? "light" : "dark" }));
    }
  }, [dark, dispatch]);

  useEffect(() => {
    if (user.lang && user.lang !== i18n.language) {
      i18n.changeLanguage(user.lang);
    }
  }, [user, i18n]);

  useEffect(() => {
    if (formData && formData.total > 0) {
      navigate(AppRoutes.INFOS_VALIDATIONS, {
        state: {
          formData: {
            activity_level: formData.items[0].activity_level,
            birth_date: formData.items[0].birth_date,
            breed: formData.items[0].breed,
            identification: formData.items[0].identification,
            is_sterilized: formData.items[0].is_sterilized,
            last_vaccination_date: formData.items[0].last_vaccination_date,
            last_vet_visit_date: formData.items[0].last_vet_visit_date,
            name: formData.items[0].name,
            sex: formData.items[0].sex,
            species: formData.items[0].species,
            tracker_id: formData.items[0].tracker_id,
            weight: formData.items[0].weight,
          },
          id: formData.items[0].id,
          noArrow: true,
        },
      });
    }
  }, [formData, navigate]);

  const isLoading = isLoadingMyUser;

  return (
    <Container>
      {myUser && !isLoading ? (
        <ComponentSwitcher
          timeLine={timeLine}
          setTimeLine={setTimeLine}
          formDataFromLocation={location.state && location.state.formData}
        />
      ) : (
        <SpinnerLoader />
      )}
    </Container>
  );
};

export default MainPage;
