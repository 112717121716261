import { Dispatch, SetStateAction, useEffect, useState } from "react";

import ConditionalWrapper from "../../atoms/ConditionalWrapper/ConditionalWrapper";
import StartingPage from "../StartingPage/StartingPage";
import Header from "../../templates/Header/Header";
import First from "../../molecules/First/First";
import Seconde from "../../molecules/Seconde/Seconde";
import Third from "../../molecules/Third/Third";
import Fourth from "../../molecules/Fourth/Fourth";
import Fifth from "../../molecules/Fifth/Fifth";
import Sixth from "../../molecules/Sixth/Sixth";
import Seventh from "../../molecules/Seventh/Seventh";
import Eightth from "../../molecules/Eighth/Eighth";
import Ninth from "../../molecules/Ninth/Ninth";
import Tenth from "../../molecules/Tenth/Tenth";
import Eleventh from "../../molecules/Eleventh/Eleventh";
import { Animal } from "../../../types/rtk/animal/animal";

interface Props {
  timeLine: number;
  setTimeLine: Dispatch<SetStateAction<number>>;
  formDataFromLocation?: Partial<Animal>;
}

const ComponentSwitcher = ({
  timeLine,
  setTimeLine,
  formDataFromLocation,
}: Props) => {
  const [formData, setFormData] = useState<Partial<Animal>>({
    activity_level: null,
    birth_date: "",
    breed: "",
    identification: "",
    is_sterilized: null,
    last_vaccination_date: "",
    last_vet_visit_date: "",
    name: "",
    sex: "",
    species: "",
    tracker_id: 0,
    weight: "0",
  });

  useEffect(() => {
    if (formDataFromLocation) {
      setFormData(formDataFromLocation);
    }
  }, [formDataFromLocation]);

  const props = {
    formData: formData,
    setFormData: setFormData,
    timeLine: timeLine,
    setTimeLine: setTimeLine,
  };

  const handleSwitchComponent = () => {
    switch (timeLine) {
      case 0:
        return <StartingPage timeLine={timeLine} setTimeLine={setTimeLine} />;
      case 1:
        return <First {...props} />;
      case 2:
        return <Seconde {...props} />;
      case 3:
        return <Third {...props} />;
      case 4:
        return <Fourth {...props} />;
      case 5:
        return <Fifth {...props} />;
      case 6:
        return <Sixth {...props} />;
      case 7:
        return <Seventh {...props} />;
      case 8:
        return <Eightth {...props} />;
      case 9:
        return <Ninth {...props} />;
      case 10:
        return <Tenth {...props} />;
      case 11:
        return <Eleventh {...props} />;
      default:
        return <p>error</p>;
    }
  };

  return (
    <ConditionalWrapper
      condition={timeLine !== 0}
      wrapper={(children) => (
        <Header timeLine={timeLine} setTimeLine={setTimeLine}>
          {children}
        </Header>
      )}
    >
      {handleSwitchComponent()}
    </ConditionalWrapper>
  );
};

export default ComponentSwitcher;
