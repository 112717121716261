import { Box, Typography } from "@mui/material";
import { CSSProperties, ReactNode } from "react";

interface Props {
  component?: "p" | "span";
  children?: ReactNode;
  className?: string;
  style?: CSSProperties;
  textColor?: string;
}

const Text = ({
  children,
  component = "p",
  className,
  style,
  textColor,
}: Props) => {
  return (
    <Box className={`Text ${className}`} sx={style}>
      <Typography
        textAlign="center"
        component={component}
        sx={{ color: textColor }}
      >
        {children}
      </Typography>
    </Box>
  );
};

export default Text;
