import { SyntheticEvent } from "react";
import { Autocomplete, TextField } from "@mui/material";

interface Props {
  label: string;
  value: { label: string; value: string } | null;
  onChange: (
    event: SyntheticEvent<Element, Event>,
    value: { label: string; value: string } | null
  ) => void;
  options: { label: string; value: string }[];
  className?: string;
}

const InputAutocomplete = ({
  label,
  value,
  onChange,
  options,
  className,
}: Props) => (
  <Autocomplete
    className={`InputAutocomplete ${className}`}
    disablePortal
    value={value}
    getOptionLabel={(option) => option.label}
    getOptionKey={(obj) => obj.value}
    onChange={onChange}
    options={options}
    renderInput={(params) => <TextField {...params} label={label} />}
  />
);

export default InputAutocomplete;
