import { useTranslation } from "react-i18next";

import TouchButton from "../../atoms/TouchButton/TouchButton";
import Dog from "../../../assets/Dog.svg";
import Cat from "../../../assets/Cat.svg";
import { QuestionProps } from "../../../types/components/molecules/questionProps";

const First = ({
  formData,
  setFormData,
  timeLine,
  setTimeLine,
}: QuestionProps) => {
  const { t } = useTranslation();
  const handleTimeLine = () => {
    setTimeout(() => {
      setTimeLine(timeLine + 1);
    }, 250);
  };

  return (
    <>
      <TouchButton
        text={t("animalInfo:QUESTION_ONE.CAT")}
        active={formData.species === "cat"}
        logoPath={Cat}
        onClick={() => {
          setFormData({ ...formData, species: "cat" });
          handleTimeLine();
        }}
      />
      <TouchButton
        text={t("animalInfo:QUESTION_ONE.DOG")}
        active={formData.species === "dog"}
        logoPath={Dog}
        onClick={() => {
          setFormData({ ...formData, species: "dog" });
          handleTimeLine();
        }}
      />
    </>
  );
};

export default First;
